import React, { ReactElement } from 'react';
import { Box } from '@chakra-ui/react';
import { graphql, PageProps } from 'gatsby';
import { ComingSoon } from '../components/ComingSoon';
import { Heading } from '../components/Heading';
import { LinkCardEventPost } from '../components/LinkCardEventPost';
import { Main } from '../components/Main';
import { PaginatedGrid } from '../components/PaginatedGrid';
import { usePageContext } from '../context/PageContext';
import { useSortedEvents } from '../utils/hooks';
import { EventPostQuery } from './EventPost';
import { EventPostImportedQuery } from './EventPostImported';

const TRANS = {
  upcoming: {
    en: 'Upcoming events',
    fi: 'Tulevat tapahtumat',
  },
  past: {
    en: 'Past events',
    fi: 'Menneet tapahtumat',
  },
};

export type EventCollectionQuery = {
  contentfulPageEventCollection: {
    title: string;
    description?: string;
  };
  allContentfulPageEventPost: {
    nodes: EventPostQuery['contentfulPageEventPost'][];
  };
  allContentfulPageEventPostImported: {
    nodes: EventPostImportedQuery['contentfulPageEventPostImported'][];
  };
};

const EventCollection = ({
  data,
}: PageProps<EventCollectionQuery>): ReactElement => {
  const { locale } = usePageContext();
  const {
    contentfulPageEventCollection,
    allContentfulPageEventPost,
    allContentfulPageEventPostImported,
  } = data;
  const { title } = contentfulPageEventCollection;
  const posts = allContentfulPageEventPost.nodes;
  const { upcoming, past } = useSortedEvents(posts);
  const imported = allContentfulPageEventPostImported.nodes;

  return (
    <Main pageTitle={title} variant={'narrow'}>
      {!posts?.length ? (
        <ComingSoon />
      ) : (
        <Box marginY={'2em'}>
          {!upcoming.length ? null : (
            <>
              <PaginatedGrid
                itemsPerPage={20}
                items={upcoming.map((post, i) => (
                  <LinkCardEventPost
                    key={`upcoming-${i}`}
                    variant={'horizontalSmall'}
                    language={locale || 'fi'}
                    {...post}
                  />
                ))}
                gap={'1rem'}
              />
            </>
          )}
          {!past.length && !imported.length ? null : (
            <>
              <Heading level={3} as={'h2'} marginBottom={'1em'}>
                {TRANS.past[locale]}
              </Heading>
              <PaginatedGrid
                itemsPerPage={20}
                items={past
                  .map((post, i) => (
                    <LinkCardEventPost
                      key={`past-${i}`}
                      variant={'horizontalSmaller'}
                      language={locale || 'fi'}
                      {...post}
                    />
                  ))
                  .concat(
                    imported.map(({ slug, ...rest }, i) => (
                      <LinkCardEventPost
                        key={`imported-${i}`}
                        variant={'horizontalSmaller'}
                        language={'fi'}
                        slug={`i/${slug}`}
                        {...rest}
                      />
                    ))
                  )}
                gap={'0.25rem'}
              />
            </>
          )}
        </Box>
      )}
    </Main>
  );
};

export default EventCollection;

export const query = graphql`
  query ($id: String!, $locale: String!) {
    contentfulPageEventCollection(id: { eq: $id }) {
      ...EventCollection
    }
    allContentfulPageEventPost(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: start, order: DESC }
    ) {
      nodes {
        ... on ContentfulPageEventPost {
          ...EventPostLink
        }
      }
    }
    allContentfulPageEventPostImported(
      filter: { node_locale: { eq: "fi" } }
      sort: { fields: start, order: DESC }
    ) {
      nodes {
        ... on ContentfulPageEventPostImported {
          ...EventPostImportedLink
        }
      }
    }
  }
`;
